/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { httpClient } from 'api';
import { Toast } from 'primereact/toast';
import { PageMissing } from 'components/PageMissing';
import { Button } from 'components/_new/Button';

// contexts
import { GlobalContextProvider } from 'contexts/globalContext';
import { AuthContextProvider } from 'contexts/authContext';
import { PanelContextProvider } from 'contexts/panelContext';

// auth
import { Verify } from 'components/auth/Verify';
import AuthContainer from 'components/auth/AuthContainer';
import Register from 'components/auth/Register';
import PasswordSetup from 'components/auth/PasswordSetup';
import Login from 'components/auth/Login';
import PasswordReset from 'components/auth/PasswordReset';
import EmailChangeConfirm from 'components/auth/EmailChangeConfirm';
import RegisterOrganization from 'components/auth/RegisterOrganization';
import Confirm from 'components/auth/Confirm';
import ExternalAuth from 'components/auth/ExternalAuth';

import RequireAuth from 'components/RequireAuth';
import ChangeOrganizationWrapper from 'components/_common/ChangeOrganizationWrapper';
import RequireTerms from 'components/auth/RequireTerms';
import { ScopeContextProvider } from 'contexts/scopeContext';

// landing page
import OrganizationLandingPage from 'components/landingPages/OrganizationLandingPage';
import ProgramLandingPage from 'components/landingPages/ProgramLandingPage';
import { Enter } from 'components/Enter';

// user panel
import UserPanelLayout from 'components/userPanel/UserPanelLayout';
import Wall from 'components/wall/Wall';
import Application from 'components/userPanel/Application';
import Pairing from 'components/pairing/Pairing';
import FilesContainer from 'components/knowledgeDatabase/FilesContainer';
import LinkedInBadgePage from 'components/LinkedInBadgePage';
import Sessions from 'components/pairSessionsNew/Sessions';
import SessionsList from 'components/pairSessionsNew/SessionsList';
import SessionAgenda from 'components/pairSessionsNew/SessionAgenda';
import { Contract } from 'components/contract/Contract';
import { FinalRaport } from 'components/finalReport/FinalReport';
import ProcessSummary from 'components/processSummary/ProcessSummary';
import { MentibotAI } from 'components/mentibotAi/MentibotAI';
import { MentibotAIList } from 'components/mentibotAi/MentibotAIList';
import { MentibotAIForm } from 'components/mentibotAi/MentibotAIForm';
import { Browse } from 'components/userFiles/Browse';
import { DispalyPage } from 'components/userFiles/DisplayPage';
import { Contact } from 'components/userPanel/Contact';
import GoalsWrapper from 'components/goals/Goals';

// program/admin panel
import OrganizationPanel from 'components/organizationPanel/OrganizationPanel';
import { OrganizationList } from 'components/organizationPanel/OrganizationList';
import { OrganizationGuard } from 'components/organizationPanel/OrganizationGuard';
import OrganizationMemberships from 'components/organizationPanel/OrganizationMemberships';
import OrganizationProgramList from 'components/organizationPanel/OrganizationProgramList';
import ProgramPanel from 'components/programPanel/ProgramPanel';
import ProgramDashboard from 'components/programPanel/programDashboard/dashboard/ProgramDashboard';
import ProgramPairList from 'components/programPanel/ProgramPairList';
import ProgramPairDetails from 'components/programPanel/ProgramPairDetails';
import ProgramPairingList from 'components/programPanel/ProgramApplicationList';
import ProgramPairingDetails from 'components/programPanel/ProgramApplicationDetails';
import ProgramMembershipList from 'components/programPanel/ProgramMembershipList';
import ProgramMembership from 'components/programPanel/ProgramMembership';
import { ProgramPostList } from 'components/programPanel/ProgramPostList';
import { ProgramPostAdd } from 'components/programPanel/ProgramPostAdd';
import { ProgramPostEdit } from 'components/programPanel/ProgramPostEdit';
import { ProgramKnowledgeAdd } from 'components/programPanel/ProgramKnowledgeAdd';
import { ProgramKnowledgeBrowser } from 'components/programPanel/ProgramKnowledgeBrowse';
import { ProgramKnowledgeEdit } from 'components/programPanel/ProgramKnowledgeEdit';
import { MyPersonalDetails } from 'components/userPanel/MyPersonalDetails';
import { PairProvider } from 'contexts/pairContext';
import { PairDetails } from 'components/userPanel/PairDetails'; // some update for any change

// other
import { Spinner } from 'components/_new/Spinner';
import { ProgramStatisticsNew } from 'components/programPanel/programStatistics/ProgramStatisticsNew';
import { WebSocketProvider } from 'components/ws/websocket.context';
import { PairMembers } from 'components/pairMembers/PairMembers';
import { FinalSurveyAccess } from 'components/finalSurveyPage/FinalSurveyAccess';
import { FinalSurveyPanel } from 'components/finalSurveyPage/FinalSurveyPanel';
import { ProgramLandingPageEditor } from 'components/programPanel/ProgramLandingPageEditor';
import ProgramConfig from 'components/programPanel/programDashboard/config/ProgramConfig';
import EditContract from './components/programPanel/EditContract';
import EditApplicationQuestions from './components/programPanel/EditApplicationQuestions';

const googleTagId = process.env.REACT_APP_GTM;
if (googleTagId) {
	TagManager.initialize({
		gtmId: googleTagId,
	});
}

export interface RouterUrlParams<T = string> {
	[key: string]: T;
}

const App = () => {
	const toastRef = useRef<Toast>(null);
	const queryClient = new QueryClient({
		defaultOptions: {
			mutations: {
				retry: false,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Error types
				onError: (error: any) => {
					if (error.response?.data?.message) {
						toastRef.current?.show({
							severity: 'error',
							life: 3000,
							summary: 'Error Message',
							detail: error.response.data.message,
						});
					}
				},
			},
			queries: {
				retry: false,
				enabled: false,
				refetchOnWindowFocus: false,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Error types
				onError: (error: any) => {
					if (error.response?.data?.message) {
						toastRef.current?.show({
							severity: 'error',
							life: 3000,
							summary: 'Error Message',
							detail: error.response.data.message,
						});
					} else {
						console.error(error);
					}
				},
			},
		},
	});

	const [apiLoading, setApiLoading] = useState(true);
	const [apiCheckDisabled, setApiCheckDisabled] = useState(false);
	const [apiError, setApiError] = useState<any>(null);
	const apiCheck = useCallback(() => {
		if (!apiCheckDisabled) {
			setApiCheckDisabled(true);
			setTimeout(() => {
				setApiCheckDisabled(false);
			}, 2500);

			setApiError(null);
			setApiLoading(true);
			httpClient
				.get<string>('status', undefined, {
					timeout: 15000,
				})
				.then((response: string) => {
					if (response === 'ok') {
						setApiLoading(false);
					}
				})
				.catch((err: any) => {
					setApiLoading(false);
					setApiError(err);
				});
		}
	}, [apiCheckDisabled]);

	useEffect(() => {
		apiCheck();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (apiError || apiLoading) {
		return (
			<div className="h-full w-full flex flex-1 justify-content-center align-items-center">
				{!apiError && apiLoading && <Spinner />}
				{apiError && !apiLoading && (
					<div className="flex flex-column justify-content-center align-items-center">
						<h2>Server nie odpowiada</h2>
						<Button onClick={apiCheck} label="Ponów próbę" disabled={apiCheckDisabled} />
					</div>
				)}
			</div>
		);
	}

	return (
		<>
			<Helmet title="Program mentoringowy" titleTemplate="%s | Mentiway">
				<meta name="description" content="Web app facilitating Mentoring Programs." />
			</Helmet>

			<QueryClientProvider client={queryClient}>
				<GlobalContextProvider
					value={{
						toastRef,
					}}
				>
					<AuthContextProvider>
						<WebSocketProvider>
							<Toast ref={toastRef as RefObject<Toast>} position="bottom-right" />
							<Routes>
								<Route index element={<Enter />} />
								<Route element={<AuthContainer />}>
									<Route path="verify" element={<Verify />} />
									<Route path="login" element={<Login />} />
									<Route path="register-organization" element={<RegisterOrganization />} />
									<Route path="register" element={<Register />} />
									<Route path="confirm-organization/:token" element={<Confirm />} />
									<Route path="password-reset" element={<PasswordReset />} />
									<Route path="confirm/:token" element={<PasswordSetup mode="confirm" />} />
									<Route path="reset/:token" element={<PasswordSetup mode="reset" />} />
									<Route path="email-change/:token" element={<EmailChangeConfirm />} />
								</Route>
								<Route path="auth/:type" element={<ExternalAuth />} />

								<Route element={<RequireAuth strict />}>
									<Route path="change-organization" element={<ChangeOrganizationWrapper />} />
									<Route path="require-terms" element={<RequireTerms />} />
								</Route>

								<Route element={<ScopeContextProvider />}>
									<Route element={<RequireAuth strict />}>
										<Route path="panel">
											<Route index element={<Navigate to="/" />} />
											<Route path=":organizationName">
												<Route index element={<Navigate to="/" />} />
												<Route path=":programName" element={<PanelContextProvider />}>
													<Route path=":type" element={<UserPanelLayout />}>
														<Route index element={<Wall />} />
														<Route
															path="personal-details"
															element={<MyPersonalDetails />}
														/>
														<Route path="application" element={<Application />} />
														<Route path="pairing" element={<Pairing />} />
														<Route path="browseMembers" element={<PairMembers />} />
														<Route path="files/*" element={<FilesContainer />} />
														<Route path="linkedin-badge" element={<LinkedInBadgePage />} />
														<Route path="pair/:pairId" element={<PairProvider />}>
															<Route index element={<Navigate to="contract" />} />
															<Route path="contract" element={<Contract />} />

															<Route path="session" element={<Sessions />}>
																<Route index element={<SessionsList />} />
																<Route
																	path=":sessionId/*"
																	element={<SessionAgenda />}
																/>
															</Route>

															<Route path="mentee-plan" element={<GoalsWrapper />} />
															<Route path="final-report" element={<FinalRaport />} />
															<Route path="final-survey" element={<FinalSurveyPanel />} />
															<Route
																path="process-summary"
																element={<ProcessSummary />}
															/>

															<Route path="mentibot-ai" element={<MentibotAI />}>
																<Route index element={<Navigate to="list" />} />
																<Route path="list" element={<MentibotAIList />} />
																<Route
																	path="form/questions"
																	element={<MentibotAIForm formType="questions" />}
																/>
																<Route
																	path="form/techniques"
																	element={<MentibotAIForm formType="techniques" />}
																/>
																<Route
																	path="form/smart"
																	element={<MentibotAIForm formType="smart" />}
																/>
																<Route
																	path="form/homework"
																	element={<MentibotAIForm formType="homework" />}
																/>
															</Route>
															<Route path="userfiles">
																<Route index element={<Browse />} />
																<Route
																	path="display/:userFileId"
																	element={<DispalyPage />}
																/>
															</Route>
															<Route path="pair-details" element={<PairDetails />} />
														</Route>
														<Route path="contact" element={<Contact />} />
													</Route>
												</Route>
											</Route>
										</Route>
										<Route path="organization/*" element={<OrganizationPanel />}>
											<Route index element={<Navigate to="list" />} />
											<Route path="list" element={<OrganizationList />} />
											<Route path=":organizationName/*" element={<OrganizationGuard />}>
												<Route index element={<Navigate to="program" />} />
												{/* <Route path="edit" element={<OrganizationEdit />} /> */}
												{/* <Route path="add-program" element={<OrganziationProgramAdd />} /> */}
												<Route path="memberships" element={<OrganizationMemberships />} />
												<Route path="program/*">
													<Route index element={<Navigate to="list" />} />
													<Route path="list" element={<OrganizationProgramList />} />
													<Route path="edit/:id" />
												</Route>
											</Route>
										</Route>
										<Route
											path="program/:organizationName/:programName/*"
											element={<ProgramPanel />}
										>
											<Route index element={<Navigate to="dashboard" />} />
											<Route path="dashboard" element={<ProgramDashboard />} />
											<Route path="config" element={<ProgramConfig />} />
											<Route path="pair/*">
												<Route index element={<Navigate to="list" />} />
												<Route path="list" element={<ProgramPairList />} />
												<Route path=":pairId" element={<ProgramPairDetails />} />
											</Route>
											<Route path="application/*">
												<Route index element={<Navigate to="list" />} />
												<Route path="list" element={<ProgramPairingList />} />
												<Route path=":applicationId" element={<ProgramPairingDetails />} />
											</Route>
											<Route path="membership/*">
												<Route index element={<Navigate to="list" />} />
												<Route path="list" element={<ProgramMembershipList />} />
												<Route path=":membershipId" element={<ProgramMembership />} />
											</Route>
											<Route path="communication/*">
												<Route index element={<Navigate to="list" />} />
												<Route path="list" element={<ProgramPostList />} />
												<Route path="add" element={<ProgramPostAdd />} />
												<Route path="edit/:id" element={<ProgramPostEdit />} />
											</Route>
											<Route path="statistics/*" element={<ProgramStatisticsNew />} />
											<Route path="edit-questions" element={<EditApplicationQuestions />} />
											<Route path="contract" element={<EditContract />} />
											<Route path="knowledge/*">
												<Route index element={<Navigate to="browse" />} />
												<Route path="add" element={<ProgramKnowledgeAdd />} />
												<Route path="edit/:id" element={<ProgramKnowledgeEdit />} />
												<Route path="browse/*" element={<ProgramKnowledgeBrowser />} />
											</Route>

											<Route path="landing-page" element={<ProgramLandingPageEditor />} />
										</Route>
									</Route>

									<Route path=":organizationName/:programName/*" element={<ProgramLandingPage />} />
									<Route path=":organizationName" element={<OrganizationLandingPage />} />
								</Route>
								<Route path="/final-survey/:uuid" element={<FinalSurveyAccess />} />

								<Route path="*" element={<PageMissing />} />
							</Routes>
							<ReactQueryDevtools initialIsOpen={false} />
						</WebSocketProvider>
					</AuthContextProvider>
				</GlobalContextProvider>
			</QueryClientProvider>
		</>
	);
};

export default App;
