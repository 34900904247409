import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { userPanelApi, landingPagesApi, applicationsApi } from 'api';
import { useScope } from 'contexts/scopeContext';
import { Question } from 'types/Question';
import { Answer, Application, ApplicationType } from 'types/Application';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { Button } from 'primereact/button';
import FormQuestion from 'components/landingPages/components/Question';
import ConfirmDialog from 'components/_common/ConfirmDialog';
import { colors } from 'theme';
import { useGlobal } from 'contexts/globalContext';
import PageMissing from 'components/PageMissing';
import { Spinner } from 'components/_new/Spinner';
import { User } from '../../../types/User';

export interface ApplyMembershipFormObject {
	form: Answer[];
}

interface FormQuestionsProps {
	setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
	type: 'mentor' | 'mentee';
	programMembershipId: number;
	questionsData: Question[];
	membershipData?: Application;
	applicationType: ApplicationType;
	editMode?: boolean;
	programPanel?: boolean;
	onSubmit?: () => void;
	programId: number;
	user?: User | null;
}
const FormQuestions: FC<FormQuestionsProps> = (props) => {
	const {
		setIsSubmitted,
		type,
		programMembershipId,
		questionsData,
		membershipData,
		applicationType,
		editMode,
		programPanel,
		programId,
		user,
		onSubmit,
	} = props;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const { toastRef } = useGlobal();
	const { currentProgramMembership, currentProgram, applicationsRefetch } = useScope();
	const [isLoading, setIsLoading] = useState(true);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [isError, setIsError] = useState(false);

	const { data: userPairs } = useQuery(
		['userPairsCurrent', currentProgram, type],
		() => userPanelApi.getCurrentPairs(String(type), Number(currentProgramMembership?.id)),
		{
			enabled: !!currentProgramMembership,
		}
	);
	const { control, handleSubmit, reset, setValue, getValues } = useForm<ApplyMembershipFormObject>({
		defaultValues: {},
	});

	const { fields } = useFieldArray({
		name: 'form',
		control,
	});

	const { mutate: cancelApplication } = useMutation(
		() => landingPagesApi.cancelProgramApplication(programMembershipId, { applicationType, applicationRole: type }),
		{
			onSuccess: () => {
				navigate(`/${organizationName}/${programName}`);
				applicationsRefetch();
			},
		}
	);

	const { mutate: postEditAnswers, isLoading: isEditSubmitting } = useMutation(
		(data: ApplicationPayload) => {
			return applicationsApi.adminApplicationEdit(programId, programMembershipId, data);
		},
		{
			onSuccess: () => {
				if (setIsSubmitted) {
					setIsSubmitted(true);
				}
				if (onSubmit) {
					onSubmit();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.application.dataUpdatedSuccessfully'),
				});
			},
		}
	);

	const { mutate: postFormAnswers, isLoading: isSubmitting } = useMutation(
		(data: ApplicationPayload) => {
			return landingPagesApi.postProgramApplications(programMembershipId, data);
		},
		{
			onSuccess: () => {
				if (setIsSubmitted) {
					setIsSubmitted(true);
				}
				if (currentProgramMembership) {
					applicationsRefetch();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.application.dataUpdatedSuccessfully'),
				});
			},
		}
	);

	useEffect(() => {
		if (editMode) {
			setIsLoading(true);
		}
		if (!editMode) {
			const tempValues = questionsData.map((question) => {
				let value = '';
				if (question.systemQuestionType === 'surname') {
					value = user?.lastName || '';
				} else if (question.systemQuestionType === 'name') {
					value = user?.firstName || '';
				}
				return {
					value,
					questionId: question.id,
				};
			});
			reset({
				form: tempValues,
			});
		}
		if (membershipData) {
			const tempValues = questionsData.map((question) => {
				let value = membershipData.answers.find((answer) => answer.questionId === question.id)?.value ?? '';
				if (value === 'true') {
					value = true;
				}
				if (value === 'false') {
					value = false;
				}
				return {
					value,
					questionId: question.id,
				};
			});
			reset({
				form: tempValues,
			});
		}

		setIsLoading(false);
	}, [questionsData, membershipData, reset, editMode]);

	const handleFormData = handleSubmit((data: ApplyMembershipFormObject) => {
		const preparedPayload = {
			applicationRole: type,
			applicationType: membershipData?.applicationType ?? questionsData[0].applicationType,
			answers: data.form,
		};
		if (programPanel) {
			postEditAnswers(preparedPayload);
		} else {
			postFormAnswers(preparedPayload);
		}
	});

	if (isError) {
		return <PageMissing />;
	}

	if (fields.length !== questionsData.length || isLoading) {
		return <Spinner />;
	}
	return (
		<>
			<ConfirmDialog
				confirmationOpen={confirmationOpen}
				setConfirmationOpen={setConfirmationOpen}
				handleAccept={cancelApplication}
			/>
			<form className="flex flex-column row-gap-4" onSubmit={handleFormData}>
				{fields.map((field, index) => (
					<FormQuestion
						key={field.id}
						field={field}
						index={index}
						questionsData={questionsData}
						setIsError={setIsError}
						control={control}
						setValue={setValue}
						getValues={getValues}
					/>
				))}

				<Button
					type="submit"
					label={editMode ? t('actions.save') : t('landingPages.buttons.sendForm')}
					className="border-round-3xl align-self-end w-6 mt-3"
					loading={isSubmitting || isEditSubmitting}
				/>

				{!!membershipData && membershipData.approved !== 'rejected' && (
					<div className="flex justify-content-end">
						<Button
							type="button"
							disabled={(userPairs || [])?.length > 0}
							tooltip={(userPairs || [])?.length > 0 ? t('landingPages.cantWithdrawTooltip') : undefined}
							tooltipOptions={{ showOnDisabled: true, position: 'top' }}
							onClick={() => setConfirmationOpen(true)}
							className="p-button-link underline"
							style={{ color: colors.gray }}
						>
							{t('landingPages.buttons.cancelFormApplication')}
						</Button>
					</div>
				)}
			</form>
		</>
	);
};

export default FormQuestions;
