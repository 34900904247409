import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Spinner } from 'components/_new/Spinner';
import { Button } from 'components/_new/Button';
import { Link } from 'components/_new/Link';
import QuestionCard from './partials/QuestionModuleItem';
import { userPanelApi } from '../../api';
import './partials/ContractModuleItem.scss';
import { AdminQuestion } from '../../types/Question';
import { useScope } from '../../contexts/scopeContext';
import AddNewQuestionForm from './AddNewQuestionForm';
import MessageBoxFull from '../_common/panel/MessageBoxFull';
import { DropdownItem } from '../_new/Dropdown';
import { capitalizeWord } from '../../utils/helpers';

const EditApplicationQuestions = () => {
	const { t } = useTranslation();
	const { data: program, organizationName, programName } = useProgramPanel();
	const [loadedData, setLoadedData] = useState<AdminQuestion[]>([]);
	const [isAddingNew, setIsAddingNew] = useState<number | null>(null);
	const [hoverIndex, setHoverIndex] = useState<number | null>(null);
	const [staleQuestions, setStaleQuestions] = useState(new Set());
	const params = useParams();
	const { currentProgram } = useScope();
	const programId = currentProgram?.id || 0;

	const { refetch: getQuestions, isLoading: isQuestionsLoading } = useQuery(
		['questions'],
		() => userPanelApi.getQuestionsAsAdmin(programId),
		{
			enabled: !!programId,
			onSuccess: (data: AdminQuestion[]) => {
				if (!loadedData.length) {
					setLoadedData(data);
				}
			},
		}
	);

	useQuery(['answeredQuestions', programId, params.role], () => userPanelApi.checkIfQuestionsAnswered(programId), {
		enabled: !!programId,
		onSuccess: (data) => {
			setStaleQuestions(new Set(data));
		},
	});

	const systemQuestionTypes = [
		{ label: 'Department', value: 'department' },
		{ label: 'Position', value: 'position' },
		{ label: 'LinkedIn', value: 'linkedin' },
		{ label: 'Expectations', value: 'expectations' },
		{ label: 'Phone', value: 'phone' },
		{ label: 'Limit', value: 'limit' },
		{ label: 'Consent', value: 'consent' },
	];

	const saveQuestionsMutation = useMutation(
		(payload: AdminQuestion[]) => userPanelApi.saveFormQuestionsAsAdmin(programId, payload),
		{
			onSuccess: (data: AdminQuestion[]) => {
				setLoadedData(data);
			},
			onError: (error) => {
				console.error('Failed to save questions:', error);
			},
		}
	);

	useEffect(() => {
		if (programId) {
			getQuestions();
		}
	}, [programId, getQuestions]);

	const staleSystemQuestions = loadedData
		.filter((question) => question.systemQuestionType)
		.map((question) => question.systemQuestionType);

	const filteredSystemQuestionTypes = systemQuestionTypes.filter(
		(question) => !staleSystemQuestions.includes(question.value)
	);

	const onDragEnd = useCallback(
		(result: { destination?: { index: number } | null; source: { index: number } }) => {
			if (!result.destination) return;

			const items = Array.from(loadedData);
			const [reorderedItem] = items.splice(result.source.index, 1);
			items.splice(result.destination.index, 0, reorderedItem);

			const updatedItems = items.map((item, index) => ({
				...item,
				priority: index + 1,
			}));

			setLoadedData(updatedItems);
			saveQuestionsMutation.mutate(updatedItems);
		},
		[loadedData, saveQuestionsMutation]
	);

	const handleSwap = useCallback(
		(currentIndex: number, swapWithIndex: number) => {
			setLoadedData((currentItems) => {
				const items = [...currentItems];
				[items[currentIndex], items[swapWithIndex]] = [items[swapWithIndex], items[currentIndex]];

				const updatedItems = items.map((item, index) => ({
					...item,
					priority: index + 1,
				}));

				saveQuestionsMutation.mutate(updatedItems);

				return updatedItems;
			});
		},
		[saveQuestionsMutation]
	);

	const handleDelete = useCallback(
		(itemId: number) => {
			setLoadedData((currentItems) => {
				const updatedItems = currentItems.filter((item) => item.id !== itemId);
				const reindexedItems = updatedItems.map((item, index) => ({
					...item,
					priority: index + 1,
				}));

				saveQuestionsMutation.mutate(reindexedItems);

				return reindexedItems;
			});
		},
		[saveQuestionsMutation]
	);

	const handleEdit = useCallback(
		(editedQuestion: Partial<AdminQuestion>) => {
			setLoadedData((prevLoadedData) => {
				const updatedData = prevLoadedData.map((item) =>
					item.id === editedQuestion.id ? { ...item, ...editedQuestion } : item
				);
				saveQuestionsMutation.mutate(updatedData);
				return updatedData;
			});
		},
		[saveQuestionsMutation]
	);

	const handleAddNewClick = useCallback((index: number) => {
		setIsAddingNew(index);
	}, []);

	const onAddNewSubmit = useCallback(
		(data: AdminQuestion) => {
			setLoadedData((currentData) => {
				const newQuestion = {
					...data,
					priority: isAddingNew! + 1,
					programId,
				};

				const updatedData = [
					...currentData.slice(0, isAddingNew!).map((item, index) => ({
						...item,
						priority: index + 1,
					})),
					newQuestion,
					...currentData.slice(isAddingNew!).map((item, index) => ({
						...item,
						priority: isAddingNew! + index + 2,
					})),
				];

				saveQuestionsMutation.mutate(updatedData);
				return updatedData;
			});
			setIsAddingNew(null);
		},
		[isAddingNew, programId, saveQuestionsMutation]
	);

	const handleCancelNew = useCallback(() => {
		setIsAddingNew(null);
	}, []);

	if (isQuestionsLoading) {
		return <Spinner />;
	}
	return program ? (
		<div>
			<Helmet title={t('programPanel.editQuestion.helmetTitle')} />
			<SubPageTitle
				title={t('programPanel.editQuestion.helmetTitle')}
				additional={program?.displayName}
				right={
					program.isProgramAdmin || program.isProgramOrganizationAdmin ? (
						<Link
							to={`/program/${organizationName}/${programName}/CONFIG`}
							// className="text-purplish-blue text-center"
						>
							{t('programPanel.backToProgramSettings')}
						</Link>
					) : undefined
				}
			/>
			{staleQuestions.size > 0 && (
				<MessageBoxFull
					severity="warn"
					content={[
						{
							text: <Trans t={t} i18nKey="programPanel.editQuestion.answersAssigned" />,
						},
					]}
				/>
			)}
			<div className="flex flex-column mb-3">
				<p>{t('programPanel.editQuestion.description')}</p>
				<p>{t('programPanel.editQuestion.description2')}</p>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="contracts">
					{(provided) => (
						<div className="flex flex-row" {...provided.droppableProps} ref={provided.innerRef}>
							<div className="w-full mr-3">
								{loadedData.map((item, index) => (
									<React.Fragment key={item.id}>
										<div
											style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }}
											onMouseEnter={() => setHoverIndex(index)}
											onMouseLeave={() => setHoverIndex(null)}
										>
											<div className="w-10">
												<Draggable draggableId={`item-${item.id}`} index={index}>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={{ ...provided.draggableProps.style, flexGrow: 1 }}
														>
															<QuestionCard
																filteredSystemQuestionTypes={
																	item.systemQuestionType
																		? [
																				...filteredSystemQuestionTypes,
																				{
																					label: capitalizeWord(
																						item.systemQuestionType
																					),
																					value: item.systemQuestionType,
																				},
																		  ]
																		: filteredSystemQuestionTypes
																}
																question={item}
																{...item}
																onSwap={handleSwap}
																index={index}
																canMoveUp={index > 0}
																canMoveDown={index < loadedData.length - 1}
																onDelete={() => handleDelete(item.id)}
																onEdit={handleEdit}
																questionAnswered={staleQuestions.has(item.id)}
															/>
														</div>
													)}
												</Draggable>
												{isAddingNew === index + 1 && (
													<AddNewQuestionForm
														onAddNewSubmit={onAddNewSubmit}
														onCancel={handleCancelNew}
														filteredSystemQuestionTypes={
															filteredSystemQuestionTypes as DropdownItem[]
														}
													/>
												)}
											</div>
											{hoverIndex === index && (
												<Button
													label={t('programPanel.editContract.addNew')}
													onClick={() => handleAddNewClick(index + 1)}
													className="p-3 ml-3 mb-4 fadein animation-duration-500"
												/>
											)}
										</div>
									</React.Fragment>
								))}
								{provided.placeholder}
							</div>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	) : (
		<Spinner />
	);
};

export default EditApplicationQuestions;
