import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import BusinessCard from 'components/_common/panel/BusinessCard';
import PairItem from 'components/pairing/Pair';
import { GroupedChoiceList } from 'types/Question';
import ValidateTextArea from 'components/_common/forms/TextArea';
// import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { Pair } from 'types/Pair';
import { Dropdown } from 'primereact/dropdown';
import { DialogAction, DialogConfirm } from 'components/_new/Dialog';
import { Spinner } from 'components/_new/Spinner';
import { Application } from 'types/Application';
import { dateFormat } from 'utils/dateFormat';
import { dayjs } from 'utils/dayjs';
import { useGlobal } from 'contexts/globalContext';
import { Button } from 'components/_new/Button';
import { Tooltip } from 'primereact/tooltip';
import PageMissing from 'components/PageMissing';
import { classNames } from 'primereact/utils';
import { getPairStatusInfo } from './utils';
import { EditApplicationDialog } from './EditApplicationDialog';
import { DeleteApplicationConfirmDialog } from './DeleteApplicationConfirmDialog';

const ProgramPairingDetails = () => {
	const { t } = useTranslation();

	const { toastRef } = useGlobal();
	const { data: program, isSuccess } = useProgramPanel();
	const params = useParams<{ applicationId: string }>();
	const applicationId = Number(params.applicationId);
	const {
		data: application,
		isLoading: applicationLoading,
		isError: applicationError,
		refetch: refetchApplication,
	} = useQuery(
		['getProgramApplication', applicationId],
		() => programsApi.getProgramAdminApplication({ programId: Number(program?.id), applicationId }),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && applicationId),
		}
	);
	const systemAnswers = application?.systemAnswers;

	const [manualModel, setManualMode] = useState(false);
	const [pairs, setPairs] = useState<Pair[]>([]);
	const currentPairsApplicationIdentifiers = (pairs || []).reduce<number[]>(
		(curr, pair) => [...curr, pair.menteeApplicationId, pair.mentorApplicationId],
		[]
	);
	const {
		// data: raw,
		isLoading: pairsLoading,
		refetch: refetchPairs,
		isRefetching,
	} = useQuery(
		['getProgramApplicationPairs', applicationId],
		() => programsApi.getProgramAdminApplicationPairing({ programId: Number(program?.id), applicationId }),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && applicationId) && !manualModel,
			onSuccess: (data) => {
				if (data && data.length > 0) {
					setPairs(data);
				}
			},
		}
	);

	const {
		mutate,
		isLoading: sending,
		isSuccess: sendEditSuccess,
	} = useMutation(programsApi.editProgramApplication, {
		onSuccess: () => {
			refetchApplication();
			setRejectConfirmDialogOpen(false);
		},
	});

	const createAreasArray = (groupedAreas: GroupedChoiceList[]) =>
		groupedAreas?.reduce((prevValue, curValue) => [...prevValue, ...(curValue?.options || [])], [] as string[]);
	const userAreas = createAreasArray(systemAnswers?.areas || []);

	const getApprovedText = (status: string) => {
		switch (status) {
			case 'accepted':
				return t('programPanel.application.statusOptions.accepted');
			case 'rejected':
				return t('programPanel.application.statusOptions.rejected');
			case 'not accepted':
			default:
				return t('programPanel.application.statusOptions.notAccepted');
		}
	};

	const handleAccept = useCallback(() => {
		if (program && application) {
			mutate({
				programId: program?.id,
				applicationId: application?.id,
				status: 'accepted',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [program, application, mutate]);

	const handleReject = useCallback(
		(reason: string) => {
			if (program && application) {
				mutate({ programId: program?.id, applicationId: application?.id, status: 'rejected', reason });
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[program, application, mutate]
	);

	const { control, trigger, getValues } = useForm<{ reason: string }>();

	const [rejectConfirmDialogOpen, setRejectConfirmDialogOpen] = useState(false);
	const [editApplicationDialogOpen, setEditApplicationDialogOpen] = useState(false);
	const [currentPair, setCurrentPair] = useState<null | number>(null);

	// handle accept pair
	const [acceptPairDialogOpen, setAcceptPairDialogOpen] = useState(false);
	const { mutate: acceptPair } = useMutation(programsApi.acceptProgramPair, {
		onSuccess: () => {
			setManualMode(true);
			setAcceptPairDialogOpen(false);
			setPairs((prev) =>
				prev.map((item) => {
					if (item.id === currentPair) {
						return {
							...item,
							acceptedByMentee: new Date().toISOString(),
							acceptedByMentor: new Date().toISOString(),
							entryMeeting: new Date().toISOString(),
							status: 'accepted',
						};
					}
					return item;
				})
			);
			setCurrentPair(null);
			refetchApplication();
			toastRef.current?.show({
				severity: 'success',
				life: 5000,
				summary: t('misc.success'),
				detail: t('programPanel.application.pairPropositions.acceptedSuccessInfo'),
			});
		},
	});
	const handleAcceptPair = useCallback(() => {
		if (currentPair && program) {
			acceptPair({ programId: program.id, pairId: currentPair });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPair, program]);

	// handle remove pairs
	const [removePairDialogOpen, setRemovePairDialogOpen] = useState(false);
	const { mutate: deletePair } = useMutation(programsApi.deleteProgramPair, {
		onSuccess: () => {
			setManualMode(true);
			setRejectConfirmDialogOpen(false);
			setPairs((prev) => prev.filter((item) => item.id !== currentPair));
			setCurrentPair(null);
			refetchApplication();
		},
	});
	const handleRemovePair = useCallback(() => {
		if (currentPair && program) {
			deletePair({ programId: program.id, pairId: currentPair });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPair, program, deletePair]);

	const { mutate: addPair, isLoading: isAddingPair } = useMutation(programsApi.addProgramPair, {
		onSuccess: (newPair) => {
			refetchPropositions();
			if (newPair) {
				const preparedPair = {
					...newPair,
					programMembership:
						application?.applicationRole === 'mentor' ? newPair.menteeMembership : newPair.mentorMembership,
					application:
						application?.applicationRole === 'mentor'
							? newPair.menteeApplication
							: newPair.mentorApplication,
				};
				setPairs((prev) => [...prev, preparedPair as unknown as Pair]);
			}
			setApplicationToAdd(null);
		},
	});

	const [applicationToAdd, setApplicationToAdd] = useState<null | number>(null);
	const handleAddPair = useCallback(() => {
		if (program && application && applicationToAdd) {
			addPair({ programId: program?.id, applicationId: application?.id, pairApplicationId: applicationToAdd });
		}
	}, [program, application, applicationToAdd, addPair]);

	// remove application
	const { mutate: deleteApplication, isLoading: deleteApplicationLoading } = useMutation(
		programsApi.deleteProgramApplications,
		{
			onSuccess: (data, variables) => {
				refetchApplication();
				toastRef.current?.show({
					severity: 'info',
					life: 5000,
					summary: t('misc.info'),
					detail: variables.quickDelete
						? t('programPanel.pairing.removeApplication.infoQuick')
						: t('programPanel.pairing.removeApplication.info'),
				});
			},
		}
	);

	// cancel remove application
	const { mutate: cancelDeleteApplication, isLoading: cancelDeleteApplicationLoading } = useMutation(
		programsApi.cancelDeleteProgramApplication,
		{
			onSuccess: () => {
				refetchApplication();
			},
		}
	);
	const handleCancelDeleting = useCallback(() => {
		cancelDeleteApplication({ programId: Number(program?.id), applicationId: Number(application?.id) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [program, application]);

	const finalDeleteApplicationLoading = cancelDeleteApplicationLoading || deleteApplicationLoading;

	const { data: propositionsData, refetch: refetchPropositions } = useQuery(
		['getAvaiablePairsForApplication', program?.id],
		() =>
			programsApi.getAvailablePairsForApplication({
				programId: Number(program?.id),
				applicationId: Number(application?.id),
			}),
		{
			enabled: Boolean(isSuccess && Number(program?.id) && application?.id),
		}
	);
	const actions = [
		{
			key: 'reject',
			submit: true,
			label: t('actions.reject'),
			onClick: () => {
				trigger('reason').then((valid) => {
					if (valid) {
						handleReject(getValues('reason'));
					}
				});
			},
			loading: sending,
		},
	];

	const [removeApplicationDialogOpen, setRemoveApplicationDialogOpen] = useState(false);
	const { control: deleteApplicationControl, handleSubmit: deleteApplicationHandleSubmit } = useForm({
		defaultValues: { withUser: false, sendMail: true, quickDelete: false },
	});
	const handleFormSubmit = deleteApplicationHandleSubmit(({ withUser, sendMail, quickDelete }) => {
		deleteApplication({
			programId: Number(program?.id),
			applicationIds: [Number(application?.id)],
			withUser,
			sendMail,
			quickDelete,
		});
	});

	const propositions = propositionsData || [];

	const currentApplicationPairLimitReached =
		application?.limitStats?.pairLimit !== null && application?.limitStats?.pairLimitReached;

	return (
		<>
			<Helmet title={t('organizationPanel.programPairingTests')} />

			{application && (
				<SubPageTitle
					title={
						application?.applicationRole === 'mentor'
							? t('programPanel.application.titleForMentor')
							: t('programPanel.application.titleForMentee')
					}
					additional={program?.displayName}
				/>
			)}

			<DialogConfirm
				message={t('programPanel.application.confirmRemovePairProposition')}
				visible={removePairDialogOpen}
				onHide={() => {
					setRemovePairDialogOpen(false);
				}}
				onConfirm={() => handleRemovePair()}
			/>

			<DialogConfirm
				message={t('programPanel.application.confirmCreatePair')}
				visible={acceptPairDialogOpen}
				onHide={() => {
					setAcceptPairDialogOpen(false);
				}}
				onConfirm={() => handleAcceptPair()}
			/>

			<DeleteApplicationConfirmDialog
				control={deleteApplicationControl}
				onConfirm={handleFormSubmit}
				visible={removeApplicationDialogOpen}
				onHide={() => {
					setRemoveApplicationDialogOpen(false);
				}}
			/>

			{application && (
				<EditApplicationDialog
					visible={editApplicationDialogOpen}
					onHide={() => {
						setEditApplicationDialogOpen(false);
					}}
					applicationData={application}
					programMembershipId={application?.programMembership.id}
					applicationRole={application.applicationRole}
					applicationType={application.applicationType}
					editMode
					programId={application.programId}
					onApplicationAdded={refetchApplication}
					onCancel={() => {
						// TODO
					}}
				/>
			)}

			<DialogAction
				title={t('programPanel.application.enterRejectReason')}
				visible={rejectConfirmDialogOpen}
				onHide={() => setRejectConfirmDialogOpen(false)}
				actions={actions}
			>
				<ValidateTextArea name="reason" control={control} required />
			</DialogAction>
			{applicationLoading && (
				<div className="flex justify-content-center">
					<Spinner />
				</div>
			)}

			{applicationError && <PageMissing />}

			{!applicationLoading && application && (
				<>
					<div className="flex flex-row justify-content-between align-items-center">
						<h2>{t('programPanel.application.pairTest')}</h2>
						{application?.limitStats && (
							<div>
								({`${t('programPanel.application.pairPropositions.acceptedPairs')}: `}
								<span
									className={classNames({
										'text-danger font-bold':
											application.limitStats.pairLimit !== null &&
											application.limitStats.pairLimitReached,
										'text-success':
											application.limitStats.pairLimit !== null &&
											!application.limitStats.pairLimitReached,
									})}
								>
									{application?.limitStats?.currentPairs}
									{application?.limitStats?.pairLimit !== null && (
										<span>/{application?.limitStats?.pairLimit}</span>
									)}
								</span>
								)
							</div>
						)}
					</div>
					<BusinessCard
						data={application}
						highlightDeleted
						displayHidden
						renderBottom={() => (
							<div className="flex gap-2 justify-content-between">
								<p>
									{t('programPanel.application.applicationHasStatus', {
										status: application.finished
											? t('programPanel.application.statusOptions.finished')
											: getApprovedText(application.approved),
									})}
									{application.rejectReason ? (
										<>
											<br />
											{t('programPanel.application.rejectReason', {
												reason: application.rejectReason,
											})}
										</>
									) : null}
								</p>
								<div className="flex flex-row flex-wrap justify-content-end gap-2">
									{application.approved === 'not accepted' && (
										<>
											{!sendEditSuccess && (
												<Button
													onClick={handleAccept}
													label={t('actions.accept')}
													loading={sending}
												/>
											)}
											{!sendEditSuccess && (
												<Button
													onClick={() => setRejectConfirmDialogOpen(true)}
													label={t('actions.reject')}
													variant="primary-text"
													disabled={sending}
												/>
											)}
										</>
									)}
									{application.approved === 'accepted' && (
										<>
											<Button
												onClick={() => setEditApplicationDialogOpen(true)}
												label={t('programPanel.application.editApplicationData')}
												variant="primary-text"
												loading={sending}
												disabled={!application}
											/>
											<Button
												onClick={() => setRejectConfirmDialogOpen(true)}
												label={t('programPanel.application.withdrawApplication')}
												variant="primary-text"
												loading={sending}
											/>
										</>
									)}
									{application.deletedAt && (
										<Button
											onClick={handleCancelDeleting}
											label={t('programPanel.pairing.removeApplication.cancelAction', {
												datetime: dateFormat(dayjs(application.deletedAt).toDate(), 'date'),
											})}
											variant="primary-text"
											loading={sending}
										/>
									)}
									{!application.deletedAt && (
										<Button
											onClick={() => setRemoveApplicationDialogOpen(true)}
											label={t('programPanel.pairing.removeApplication.removeAction')}
											variant="primary-text"
											loading={finalDeleteApplicationLoading}
										/>
									)}
								</div>
							</div>
						)}
					/>
					<div>
						<div className="flex justify-content-between align-items-center flex-wrap gap-2 mt-2">
							<div className="flex-1">
								<h2>{t('programPanel.application.pairPropositions.title')}</h2>
								{application?.applicationRole === 'mentor' && (
									<p>{t('programPanel.application.pairPropositions.descMentor')}</p>
								)}
								{application?.applicationRole === 'mentee' && (
									<p>{t('programPanel.application.pairPropositions.descMentee')}</p>
								)}
							</div>
							<Button
								onClick={() => {
									setManualMode(false);
									refetchPairs();
								}}
								label={t('actions.refresh')}
								loading={pairsLoading || isRefetching}
							/>
						</div>
						{pairs?.length === 0 && (
							<p className="text-gray">
								{application?.approved === 'not accepted'
									? t(
											'programPanel.application.pairPropositions.noPropositionsApplicationNotAccepted'
									  )
									: t('programPanel.application.pairPropositions.noPropositionsApplicationAccepted')}
							</p>
						)}
						{(pairs || []).length > 0 && (
							<ul className="p-list-unstylled">
								{(pairs || [])?.map((pair) => (
									<li key={pair.id}>
										<PairItem
											data={pair}
											userAreas={userAreas}
											rednerBottom={() => {
												// const applicationHasLimit = application?.pairLimitReached;
												const targetHasLimit =
													application?.applicationRole === 'mentee'
														? pair.mentorApplication?.limitStats?.pairLimitReached
														: pair.menteeApplication?.limitStats?.pairLimitReached;
												// const hasLimit = targetHasLimit;
												const isAccepted = pair.acceptedByMentee || pair.acceptedByMentor;
												const isRejected = pair.status === 'rejected';
												// const hasAction = Boolean(pair.entryMeeting || isAccepted);
												const hasContract = Boolean(pair.contractId);
												return (
													<>
														<hr />
														<div className="flex justify-content-between align-items-center flex-wrap gap-2">
															<div>
																{getPairStatusInfo(pair)},{' '}
																<Link
																	to={`/program/${program?.name}/pair/${pair.id}`}
																	className="text-purplish-blue"
																	data-pr-tooltip="test"
																>
																	{t('programPanel.application.pairDetails')}
																</Link>
																.
															</div>
															<div className="flex gap-2">
																<Tooltip
																	target={`#remove-button-${pair.id}`}
																	position="top"
																/>
																<span
																	id={`remove-button-${pair.id}`}
																	data-pr-tooltip={
																		hasContract
																			? t(
																					'programPanel.application.pairPropositions.cantRemovePairProposition'
																			  )
																			: undefined
																	}
																>
																	<Button
																		onClick={() => {
																			setCurrentPair(pair.id);
																			setRemovePairDialogOpen(true);
																		}}
																		label={t(
																			'programPanel.application.pairPropositions.removePairProposition'
																		)}
																		variant="danger"
																		disabled={hasContract}
																	/>
																</span>

																<Tooltip
																	target={`#accept-button-${pair.id}`}
																	position="top"
																	disabled={false}
																/>
																<span
																	id={`accept-button-${pair.id}`}
																	data-pr-tooltip={
																		// eslint-disable-next-line no-nested-ternary
																		pair.entryMeeting
																			? t(
																					'programPanel.application.pairPropositions.entryMeeting'
																			  )
																			: // eslint-disable-next-line no-nested-ternary
																			isAccepted
																			? t(
																					'programPanel.application.pairPropositions.accepted'
																			  )
																			: // eslint-disable-next-line no-nested-ternary
																			currentApplicationPairLimitReached
																			? t(
																					'programPanel.application.pairPropositions.currentApplicationPairLimitReached'
																			  )
																			: targetHasLimit
																			? t(
																					'programPanel.application.pairPropositions.limitReached'
																			  )
																			: undefined
																	}
																>
																	<Button
																		onClick={() => {
																			setCurrentPair(pair.id);
																			setAcceptPairDialogOpen(true);
																		}}
																		label={t(
																			'programPanel.application.pairPropositions.acceptPair'
																		)}
																		disabled={
																			isRejected ||
																			Boolean(isAccepted) ||
																			currentApplicationPairLimitReached ||
																			targetHasLimit
																		}
																	/>
																</span>
															</div>
														</div>
													</>
												);
											}}
										/>
									</li>
								))}
							</ul>
						)}

						<div className="mt-6">
							<div className="flex-1">
								<h2>{t('programPanel.application.pairPropositions.addNewPropositionTitle')}</h2>
								<p>{t('programPanel.application.pairPropositions.addNewPropositionDescription')}</p>
							</div>
							<div className="flex gap-2">
								<Dropdown
									placeholder={t(
										'programPanel.application.pairPropositions.addPairPropositionPlaceholder'
									)}
									className="flex-1"
									value={applicationToAdd}
									onChange={({ value }) => {
										setApplicationToAdd(value);
									}}
									dataKey="id"
									optionValue="id"
									options={propositions
										.sort((a, b) =>
											String(a.programMembership.user.lastName || '').localeCompare(
												String(b.programMembership.user.lastName || ''),
												'en',
												{ sensitivity: 'base' }
											)
										)
										.map((proposition: Application) => {
											const displayName = `${proposition.programMembership.user.lastName} ${proposition.programMembership.user.firstName}`;
											return {
												id: proposition.id,
												label: displayName,
												disabled: currentPairsApplicationIdentifiers.includes(proposition.id),
												className: proposition.deletedAt ? 'text-error' : undefined,
											};
										})}
									filter
								/>
								<Button
									onClick={() => handleAddPair()}
									label={t('actions.add')}
									disabled={!applicationToAdd}
									loading={isAddingPair}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ProgramPairingDetails;
