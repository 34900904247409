import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { pairsApi, programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { dateFormat } from 'utils/dateFormat';
import { useProgramPanel } from 'contexts/programPanelContext';
import { GroupedChoiceList } from 'types/Question';
import OptionItem from 'components/pairing/OptionItem';
import { Box, BoxSection } from 'components/_new/Box';
import { Avatar } from 'components/_new/Avatar';
import { Spinner } from 'components/_new/Spinner';
import { classNames } from 'primereact/utils';
import { parseHtml } from 'utils/htmlParser';
import { userDisplayName } from '../../utils/userDisplayName';
import { getPairStatusInfo } from './utils';
import { Button } from '../_new/Button';
import { DialogAction } from '../_new/Dialog';
import { Dropdown } from '../_common/forms/Dropdown';

const ProgramPairDetails = () => {
	const { t } = useTranslation();

	const { data: program, isLoading, isSuccess } = useProgramPanel();

	const params = useParams<{ pairId: string }>();
	const pairId = Number(params.pairId);
	const [pairStatusModalOpen, setPairStatusModalOpen] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<string>();
	const {
		data: programPair,
		isSuccess: getProgramPairSuccess,
		isLoading: getProgramPairLoading,
		refetch: refetchProgramPair,
	} = useQuery(
		['getProgramPair', { programId: program?.id, pairId }],
		() => programsApi.getProgramAdminPair({ programId: Number(program?.id), pairId }),
		{
			enabled: Boolean(isSuccess && pairId),
		}
	);
	const { mutate: changePairStatus, isLoading: isChangingPairStatus } = useMutation(pairsApi.adminChangePairStatus, {
		onSuccess: (data) => {
			refetchProgramPair();
			setSelectedValue(undefined);
			setPairStatusModalOpen(false);
		},
	});

	const statusPairs = [
		{ label: t('programPanel.pair.list.status.accepted'), value: 'accepted', id: 1 },
		{ label: t('programPanel.pair.list.status.rejected'), value: 'rejected', id: 2 },
		{ label: t('programPanel.pair.list.status.finished'), value: 'finished', id: 3 },
		{ label: t('programPanel.pair.list.status.removed'), value: 'removed', id: 4 },
	];

	const handleSubmitForm = (event: { preventDefault: () => void }) => {
		event.preventDefault();
		if (selectedValue && program) {
			// Prepare payload
			const newStatus = {
				programId: program.id,
				pairId,
				payload: selectedValue,
			};
			changePairStatus(newStatus);
		}
	};
	const createAreasArray = (groupedAreas: GroupedChoiceList[]) =>
		groupedAreas?.reduce((prevValue, curValue) => [...prevValue, ...(curValue?.options || [])], [] as string[]);
	const userAreas = programPair?.menteeApplication?.systemAnswers?.areas
		? createAreasArray(programPair?.menteeApplication?.systemAnswers?.areas)
		: [];

	const getOptions = () => {
		if (createAreasArray) {
			const pairAreas = programPair?.mentorApplication?.systemAnswers?.areas
				? createAreasArray(programPair.mentorApplication.systemAnswers.areas)
				: [];
			const separatedAreas: any[] = [];
			pairAreas?.forEach((area) => {
				const isAreaMatch = userAreas?.find((userArea) => userArea === area);
				if (isAreaMatch) {
					return separatedAreas.unshift({ area, match: true });
				}
				return separatedAreas.push({ area, match: false });
			});
			return separatedAreas;
		}
		return [];
	};

	const menteeData = useMemo(() => {
		if (programPair?.menteeApplication?.systemAnswers) {
			const systemAnswers = programPair?.menteeApplication?.systemAnswers;
			const { position, expectations, areas, department } = systemAnswers;
			return {
				applicationId: programPair.menteeApplicationId,
				avatar: programPair.menteeMembership.user.avatarActive
					? programPair.menteeMembership.user.avatar
					: undefined,
				name: userDisplayName(programPair.menteeMembership.user),
				avatarName: userDisplayName(programPair.menteeMembership.user, null),
				email: programPair.menteeMembership.user.email,
				position,
				expectations,
				areas,
				department,
				lastLoginDate: programPair.menteeMembership.user.lastLoginDate
					? dateFormat(programPair.menteeMembership.user.lastLoginDate, 'full')
					: '-',
			};
		}
		return null;
	}, [programPair]);

	const mentorData = useMemo(() => {
		if (programPair?.mentorApplication?.systemAnswers) {
			const systemAnswers = programPair?.mentorApplication?.systemAnswers;
			const { position, expectations, areas, department } = systemAnswers;
			return {
				applicationId: programPair.mentorApplicationId,
				avatar: programPair.mentorMembership.user.avatarActive
					? programPair.mentorMembership.user.avatar
					: undefined,
				name: userDisplayName(programPair.mentorMembership.user),
				avatarName: userDisplayName(programPair.mentorMembership.user, null),
				email: programPair.mentorMembership.user.email,
				position,
				expectations,
				areas,
				department,
				lastLoginDate: programPair.mentorMembership.user.lastLoginDate
					? dateFormat(programPair.mentorMembership.user.lastLoginDate, 'full')
					: '-',
			};
		}
		return null;
	}, [programPair]);

	const renderContent = (content: any, separator = ', ') => {
		if (content && Array.isArray(content)) {
			return content.join(separator);
		}
		if (content && String(content).length > 0) {
			return parseHtml(content);
		}
		return null;
	};

	const questionnairesData = useMemo(() => {
		// prepare rows for goals
		const midGoals = Object.values(programPair?.questionnaires?.middle?.goals || {});
		const lastGoals = Object.values(programPair?.questionnaires?.last?.goals || {});

		// const goals = lastGoals.length !== 0 ? lastGoals : midGoals;

		const mappedGoals = (programPair?.goals || []).map((goal, index) => {
			return {
				key: `goal-${index}`,
				name: t('programPanel.goalRealizationNo', { no: index + 1 }),
				mid: `${midGoals[index] ? `${midGoals[index]}/10` : t('misc.noData')}`,
				last: `${lastGoals[index] ? `${lastGoals[index]}/10` : t('misc.noData')}`,
			};
		});

		const mappedQuestionnaires = [
			{
				key: 'menteeEngagement',
				name: t('programPanel.questionaireHeaders.menteeEngagement'),
				mid: programPair?.questionnaires?.middle?.menteeEngagement
					? `${programPair?.questionnaires?.middle?.menteeEngagement}/10`
					: t('misc.noData'),
				last: programPair?.questionnaires?.last?.menteeEngagement
					? `${programPair?.questionnaires?.last?.menteeEngagement}/10`
					: t('misc.noData'),
			},
			{
				key: 'mentorEngagement',
				name: t('programPanel.questionaireHeaders.mentorEngagement'),
				mid: programPair?.questionnaires?.middle?.mentorEngagement
					? `${programPair?.questionnaires?.middle?.mentorEngagement}/10`
					: t('misc.noData'),
				last: programPair?.questionnaires?.last?.mentorEngagement
					? `${programPair?.questionnaires?.last?.mentorEngagement}/10`
					: t('misc.noData'),
			},
			{
				key: 'menteeSatisfaction',
				name: t('programPanel.questionaireHeaders.menteeSatisfaction'),
				mid: programPair?.questionnaires?.middle?.menteeSatisfaction
					? `${programPair?.questionnaires?.middle?.menteeSatisfaction}/10`
					: t('misc.noData'),
				last: programPair?.questionnaires?.last?.menteeSatisfaction
					? `${programPair?.questionnaires?.last?.menteeSatisfaction}/10`
					: t('misc.noData'),
			},
			{
				key: 'processStandardsMaintained',
				name: t('programPanel.questionaireHeaders.processStandardsMaintained'),
				mid:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.middle?.standards !== undefined
						? programPair?.questionnaires?.middle?.standards
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
				last:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.last?.standards !== undefined
						? programPair?.questionnaires?.last?.standards
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
			},
			{
				key: 'meetingSchedule',
				name: t('programPanel.questionaireHeaders.meetingSchedule'),
				mid:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.middle?.schedule !== undefined
						? programPair?.questionnaires?.middle?.schedule
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
				last:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.last?.schedule !== undefined
						? programPair?.questionnaires?.last?.schedule
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
			},
			{
				key: 'contractRulesFollowed',
				name: t('programPanel.questionaireHeaders.contractRulesFollowed'),
				mid:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.middle?.contract !== undefined
						? programPair?.questionnaires?.middle?.contract
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
				last:
					// eslint-disable-next-line no-nested-ternary
					programPair?.questionnaires?.last?.contract !== undefined
						? programPair?.questionnaires?.last?.contract
							? t('misc.yes')
							: t('misc.no')
						: t('misc.noData'),
			},
			// {
			// 	key: 'menteeDevelopmentChanges',
			// 	name: t('programPanel.questionaireHeaders.menteeDevelopmentChanges'),
			// 	mid:
			// 		// eslint-disable-next-line no-nested-ternary
			// 		programPair?.questionnaires?.middle?.goalChanges !== undefined
			// 			? programPair?.questionnaires?.middle?.goalChanges
			// 				? t('misc.yes')
			// 				: t('misc.no')
			// 			: t('misc.noData'),
			// 	last:
			// 		// eslint-disable-next-line no-nested-ternary
			// 		programPair?.questionnaires?.last?.goalChanges !== undefined
			// 			? programPair?.questionnaires?.last?.goalChanges
			// 				? t('misc.yes')
			// 				: t('misc.no')
			// 			: t('misc.noData'),
			// },
		];

		return [...mappedGoals, ...mappedQuestionnaires];
	}, [programPair]);

	return (
		<>
			<Helmet title={t('organizationPanel.programPairs')} />
			<SubPageTitle
				title={t('organizationPanel.programPairs')}
				additional={program?.displayName}
				right={() => (
					<Link to={`/program/${program?.name}/pair/list`} className="text-purplish-blue">
						{t('programPanel.pair.backToPairList')}
					</Link>
				)}
			/>
			<DialogAction
				title={t('userPanel.mentibotAi.reply.ratingComment.title')}
				visible={pairStatusModalOpen}
				onHide={() => {
					setPairStatusModalOpen(false);
				}}
				actions={[
					{
						key: 'save',
						form: 'pair-status-form',
						label: t('actions.save'),
						loading: isChangingPairStatus,
						submit: true,
						disabled: !selectedValue,
					},
				]}
			>
				<form
					id="pair-status-form"
					className="w-full flex flex-column justify-content-between"
					onSubmit={handleSubmitForm}
				>
					<p>{t('programPanel.pair.list.chooseNewPairStatus')}</p>
					<Dropdown
						placeholder={t('programPanel.pair.list.changePairStatus')}
						className="flex-1"
						value={selectedValue}
						onChange={(event) => {
							setSelectedValue(event.value);
						}}
						options={statusPairs}
						rounded
					/>
				</form>
			</DialogAction>
			{(isLoading || getProgramPairLoading) && <Spinner />}
			{program && isSuccess && getProgramPairSuccess && programPair && menteeData && mentorData && (
				<div className="flex flex-column gap-4">
					<Box variant="white-bordered">
						<BoxSection>
							<div className="flex flex-row flex-wrap gap-2">
								<div className="flex-1">
									<div className="color-gray mb-2">{t('misc.mentee')}:</div>
									<div className="flex flex-row justify-content-start gap-4 w-full">
										<Avatar src={menteeData?.avatar} name={menteeData.avatarName} size="lg" />
										<div>
											<Link
												to={`/program/${program.name}/application/${menteeData.applicationId}`}
												className={classNames('font-bold text-3xl strong text-wrap-any')}
											>
												{menteeData.name}
											</Link>
											{menteeData.position && (
												<div className="text-sm strong">{menteeData.position}</div>
											)}
											<div className="text-sm strong">{menteeData.email}</div>
											<div>
												{t('programPanel.lastLogin')}: {menteeData.lastLoginDate}
											</div>
										</div>
									</div>
								</div>
								<div className="flex-1">
									<div className="color-gray mb-2">{t('misc.mentor')}:</div>
									<div className="flex flex-row justify-content-start gap-4 w-full">
										<Avatar src={mentorData.avatar} name={mentorData.avatarName} size="lg" />
										<div>
											<Link
												to={`/program/${program.name}/application/${mentorData.applicationId}`}
												className="font-bold text-3xl strong text-wrap-any"
											>
												{mentorData.name}
											</Link>
											{mentorData.position && (
												<div className="text-sm strong">{mentorData.position}</div>
											)}
											<div className="text-sm strong">{mentorData.email}</div>
											<div>
												{t('programPanel.lastLogin')}: {mentorData.lastLoginDate}
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className="mt-4">
								<div className="color-gray">{t('programPanel.pair.menteeGoals')}:</div>
								<div className="flex mt-1 gap-2 flex-wrap flex-start sm:flex-column md:flex-row">
									{getOptions().map((area) => (
										<OptionItem key={area.area} option={area} />
									))}
								</div>
							</div>
							<div className="my-4">
								<div className="color-gray">{t('programPanel.pair.menteeExpectations')}:</div>
								<div className="flex mt-1 gap-2 flex-wrap flex-start">
									{renderContent(menteeData.expectations)}
								</div>
							</div>
							<hr />
							<div className="flex justify-content-between align-items-center">
								<div>{getPairStatusInfo(programPair)}, </div>
								{programPair?.contractId && (
									<div>
										<Button
											onClick={() => setPairStatusModalOpen(true)}
											label={t('programPanel.pair.list.changePairStatus')}
										/>
									</div>
								)}
							</div>
						</BoxSection>
					</Box>

					<Box variant="white-bordered">
						<BoxSection header={t('userPanel.navigation.contract')}>
							<div className="flex flex-row">
								<div className="col-6">
									<div className="flex flex-row">
										<div className="col-6">{t('programPanel.pair.menteeSign')}:</div>
										<div className="col-6">
											{programPair?.contract?.initialSignDateMentee ? (
												<span>
													<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
													{dateFormat(programPair?.contract?.initialSignDateMentee, 'full')}
												</span>
											) : (
												<span>
													<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />-
												</span>
											)}
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="flex flex-row">
										<div className="col-6">{t('programPanel.pair.mentorSign')}:</div>
										<div className="col-6">
											{programPair?.contract?.initialSignDateMentor ? (
												<span>
													<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
													{dateFormat(programPair?.contract?.initialSignDateMentor, 'full')}
												</span>
											) : (
												<span>
													<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />-
												</span>
											)}
										</div>
									</div>
								</div>
							</div>
						</BoxSection>
					</Box>

					<Box variant="white-bordered">
						<BoxSection header={t('userPanel.navigation.mentoringPlan')}>
							<div className="flex flex-row">
								<div className="col-6">
									<div className="flex flex-row">
										<div className="col-6">{t('programPanel.pair.menteeSign')}:</div>
										<div className="col-6">
											{programPair?.contract?.goalSignDateMentee ? (
												<span>
													<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
													{dateFormat(programPair?.contract?.goalSignDateMentee, 'full')}
												</span>
											) : (
												<span>
													<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />-
												</span>
											)}
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="flex flex-row">
										<div className="col-6">{t('programPanel.pair.mentorSign')}:</div>
										<div className="col-6">
											{programPair?.contract?.goalSignDateMentor ? (
												<span>
													<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
													{dateFormat(programPair?.contract?.goalSignDateMentor, 'full')}
												</span>
											) : (
												<span>
													<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />-
												</span>
											)}
										</div>
									</div>
								</div>
							</div>
						</BoxSection>
					</Box>

					<Box variant="white-bordered">
						<BoxSection header={t('userPanel.progressSteps.steps.after_mid_session')}>
							<table className="p-datatable w-full">
								<thead className="p-datatable-thead">
									<tr>
										<th>{t('programPanel.number')}</th>
										<th>{t('programPanel.date')}</th>
										<th>{t('programPanel.pair.menteeSessionRating')}</th>
										<th>{t('programPanel.pair.mentorSessionRating')}</th>
									</tr>
								</thead>
								<tbody className="p-datatable-tbody">
									{programPair.sessions?.map((session) => (
										<tr key={session.id}>
											<td>
												<div className="flex flex-row gap-2">
													<span>{session.sessionNumber}</span>
													{session.sessionType === 'middle' && (
														<span>{t('misc.middleSession')}</span>
													)}
												</div>
											</td>
											<td>
												{session.date ? (
													<div className="flex flex-row gap-2">
														<span>{dateFormat(session.date, 'full')}</span>
														{session.finished && (
															<i className="pi pi-check-circle text-green-400" />
														)}
													</div>
												) : (
													'-'
												)}
											</td>
											<td>{session?.menteeRating ? `${session?.menteeRating}/5` : '-'}</td>
											<td>{session?.mentorRating ? `${session?.mentorRating}/5` : '-'}</td>
										</tr>
									))}
								</tbody>
							</table>
						</BoxSection>
					</Box>

					<Box variant="white-bordered">
						<BoxSection header={t('organizationPanel.questionnaireHeader')}>
							<table className="p-datatable w-full">
								<thead className="p-datatable-thead">
									<tr>
										<th>{t('organizationPanel.questionBody')}</th>
										<th>{t('organizationPanel.questionnaireMid')}</th>
										<th>{t('organizationPanel.questionnaireLast')}</th>
									</tr>
								</thead>
								<tbody className="p-datatable-tbody">
									{questionnairesData.map((row) => (
										<tr key={row.key}>
											<td>{row.name}</td>
											<td>{row.mid}</td>
											<td>{row.last}</td>
										</tr>
									))}
								</tbody>
							</table>
						</BoxSection>
					</Box>
				</div>
			)}
		</>
	);
};

export default ProgramPairDetails;
