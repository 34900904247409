import React, { useEffect, useState, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { AxiosError } from 'axios';
import { landingPagesApi } from 'api';
import { useAuth } from 'contexts/authContext';
import { useScope } from 'contexts/scopeContext';
import { useGlobal } from 'contexts/globalContext';
import PageMissing from 'components/PageMissing';
import { LandingPageFormsAvailability } from 'types/LandingPageFormsAvailability';
import RequireAuth from 'components/RequireAuth';
import { PageLoading } from 'components/PageLoading';
import { fixedT } from 'locale';
import { MyAccountDialog } from 'components/myAccount/MyAccountDialog';
import { MyCalendarDialog } from 'components/myCalendar/MyCalendarDialog';
import { ChangeAvatarDialog } from 'components/myAccount/components/ChangeAvatarDialog';
import LandingPageFormAccess from './components/LandingPageFormAccess';
import LandingPageWrapper from './components/LandingPageWrapper';
import { ProgramFinishedPage } from '../ProgramFinishedPage';
import '../../landing-pages.scss';

const ProgramLandingPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName } = useParams();

	const {
		toastRef,
		setMyAccountModalOpen,
		myAccountModalOpen,
		setMyCalendarModalOpen,
		myCalendarModalOpen,
		avatarModalIsOpen,
		setAvatarModalIsOpen,
	} = useGlobal();
	const { currentUser, currentUserRefetch, ready, isLogged } = useAuth();
	const { currentProgram, currentProgramRefetch, scopePath, applicationsData } = useScope();

	const [checkOrganizationMembershipCompleted, setCheckOrganizationMembershipCompleted] = useState(false);
	const [checkProgramMembershipCompleted, setCheckProgramMembershipCompleted] = useState(false);
	const organizationTitle = String(currentProgram?.organization?.displayName);

	const {
		data: organizationData,
		refetch: organizationRefetch,
		// isLoading: organizationLoading,
		// isFetching: organizationFetching,
		isFetched: organizationFetched,
		// isError: organizationError,
	} = useQuery(['organization', organizationName], () => landingPagesApi.getOrganization(String(organizationName)), {
		enabled: Boolean(ready && organizationName),
		onError: (error) => {
			if (error instanceof AxiosError) {
				const { response } = error;
				if (response?.status === 403) {
					if (!currentUser) {
						toastRef?.current?.show({ severity: 'info', detail: t('auth.pageAuthRequired') });
						navigate({
							pathname: '/login',
							search: `?redirectUrl=/${encodeURI(String(organizationName))}`,
						});
					} else if (!joinOrganizationLoading) {
						joinOrganizationMutate();
					}
				}
			}
		},
	});

	const { mutate: joinOrganizationMutate, isLoading: joinOrganizationLoading } = useMutation(
		() => landingPagesApi.joinOrganization(String(organizationName)),
		{
			onSuccess: () => {
				toastRef?.current?.show({ severity: 'success', detail: 'Dołączyłeś do organizacji' });
				currentUserRefetch();
				organizationRefetch();
				setCheckOrganizationMembershipCompleted(true);
			},
			onError: () => {
				setCheckOrganizationMembershipCompleted(true);
			},
		}
	);

	const hasCurrentOrganizationMembership = useMemo(() => {
		if (!ready || !organizationFetched) {
			return undefined;
		}
		return Boolean(
			currentUser &&
				organizationData &&
				currentUser.organizationMemberships.find(({ organizationId }) => organizationData.id === organizationId)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, organizationData]);

	useEffect(() => {
		if (ready && !checkOrganizationMembershipCompleted && !joinOrganizationLoading) {
			if (currentUser && hasCurrentOrganizationMembership === false) {
				joinOrganizationMutate();
			} else {
				setCheckOrganizationMembershipCompleted(true);
			}
		}
	}, [
		ready,
		currentUser,
		hasCurrentOrganizationMembership,
		joinOrganizationMutate,
		joinOrganizationLoading,
		navigate,
		checkOrganizationMembershipCompleted,
	]);

	const {
		data: programData,
		refetch: programRefetch,
		isLoading: programLoading,
		isFetching: programFetching,
		isFetched: programFetched,
		isError: programError,
	} = useQuery(
		['GET_PROGRAM', scopePath],
		() => landingPagesApi.getProgram(String(organizationName), String(programName)),
		{
			enabled: ready && Boolean(scopePath) && organizationFetched && checkOrganizationMembershipCompleted,
			onError: (error) => {
				if (error instanceof AxiosError) {
					const { response } = error;
					// on forbidden resource
					if (response?.status === 403) {
						if (!currentUser) {
							toastRef?.current?.show({ severity: 'info', detail: t('auth.pageAuthRequired') });
							navigate({
								pathname: '/login',
								search: `?redirectUrl=/${encodeURI(String(scopePath))}`,
							});
						} else if (!joinProgramLoading) {
							joinProgramMutate();
						}
					}
				}
			},
		}
	);

	const hasCurrentProgramMembership = useMemo(() => {
		if (!ready || !programFetched) {
			return undefined;
		}
		return Boolean(
			currentUser &&
				programData &&
				currentUser.programMemberships.find(({ programId }) => programData.id === programId)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, programFetched]);

	const { mutate: joinProgramMutate, isLoading: joinProgramLoading } = useMutation(
		() => landingPagesApi.joinProgram(String(organizationName), String(programName)),
		{
			onSuccess: () => {
				toastRef?.current?.show({ severity: 'success', detail: 'Dołączyłeś do programu' });
				currentUserRefetch();
				currentProgramRefetch();
				programRefetch();
				setCheckProgramMembershipCompleted(true);
			},
			onError: () => {
				setCheckProgramMembershipCompleted(true);
			},
		}
	);

	// join program if we have not membership
	useEffect(() => {
		if (ready && hasCurrentProgramMembership !== undefined) {
			if (
				currentUser &&
				!checkProgramMembershipCompleted &&
				!joinProgramLoading &&
				hasCurrentProgramMembership === false
			) {
				joinProgramMutate();
			} else {
				setCheckProgramMembershipCompleted(true);
			}
		}
	}, [
		ready,
		currentUser,
		navigate,
		checkProgramMembershipCompleted,
		joinProgramLoading,
		hasCurrentProgramMembership,
		joinProgramMutate,
	]);

	// program loading state
	if (!programFetched) {
		return <PageLoading text={t('landingPages.loadProgramData')} />;
	}

	// joining state
	if (joinProgramLoading || checkProgramMembershipCompleted === false) {
		return <PageLoading text={t('landingPages.joiningTheProgram')} />;
	}

	// program not found, or other error
	if ((programFetched && !programData) || programError) {
		return <PageMissing />;
	}

	// organization require auth, but we have not membership
	if (ready && isLogged && checkProgramMembershipCompleted && !hasCurrentProgramMembership) {
		return <PageMissing />;
	}

	if (currentProgram?.finished) {
		return <ProgramFinishedPage nextProgramName={currentProgram.nextProgramName} />;
	}

	// computed
	const hasSendedMentorApplication =
		applicationsData?.findIndex(({ applicationRole }) => applicationRole === 'mentor') !== -1;
	const hasSendedMenteeApplication =
		applicationsData?.findIndex(({ applicationRole }) => applicationRole === 'mentee') !== -1;

	// for non-nullable typing
	if (!currentProgram) {
		return null;
	}

	const formsAvailability: LandingPageFormsAvailability = {
		entryMentorRecruitment: currentProgram.entryMentorRecruitment,
		mentorRecruitment: currentProgram.mentorRecruitment,
		entryMenteeRecruitment: currentProgram.entryMenteeRecruitment,
		menteeRecruitment: currentProgram.menteeRecruitment,
		isMentorRecruitmentAvailable:
			currentProgram.mentorRecruitment &&
			(hasSendedMentorApplication ||
				!currentUser ||
				hasCurrentProgramMembership ||
				currentProgram.entryMentorRecruitment),
		isMenteeRecruitmentAvailable:
			currentProgram.menteeRecruitment &&
			(hasSendedMenteeApplication ||
				!currentUser ||
				hasCurrentProgramMembership ||
				currentProgram.entryMenteeRecruitment),
	};

	return (
		<>
			<Helmet title={currentProgram?.displayName} titleTemplate={`%s - ${organizationTitle} | Mentiway`}>
				<meta
					name="description"
					content={fixedT(currentProgram.language, 'landingPages.description', {
						programDisplayName: currentProgram?.displayName,
						organizationDisplayName: currentProgram?.organization?.displayName,
					})}
				/>
			</Helmet>

			{isLogged && (
				<>
					<MyAccountDialog
						visible={myAccountModalOpen}
						onHide={() => {
							setMyAccountModalOpen(false);
						}}
					/>
					<ChangeAvatarDialog visible={avatarModalIsOpen} onHide={() => setAvatarModalIsOpen(false)} />
					<MyCalendarDialog
						visible={myCalendarModalOpen}
						onHide={() => {
							setMyCalendarModalOpen(false);
						}}
					/>
				</>
			)}

			<Routes>
				<Route
					element={<LandingPageWrapper programData={currentProgram} formsAvailability={formsAvailability} />}
				>
					<Route path="/index" element={<Navigate to={{ pathname: `/${currentProgram.name}` }} replace />} />
					{currentProgram.landingPageMode === 'module' && (
						<Route
							index
							element={currentProgram.landingPageModules?.map(
								({ id, compiled, module }) =>
									compiled && (
										<div
											key={id}
											className={`lp-custom-module-${String(module)}`}
											dangerouslySetInnerHTML={{ __html: compiled }}
										/>
									)
							)}
						/>
					)}
					{currentProgram.landingPageMode === 'html' && (
						<Route index element={parse(currentProgram.landingPage.htmlContent || '')} />
					)}
					<Route element={<RequireAuth strict />}>
						<Route
							path="apply-mentor"
							element={
								<LandingPageFormAccess
									type="mentor"
									programData={currentProgram}
									applicationType={formsAvailability.mentorRecruitment ? 'proper' : 'entry'}
									recruitmentEnabled={formsAvailability.isMentorRecruitmentAvailable}
								/>
							}
						/>
						<Route
							path="apply-mentee"
							element={
								<LandingPageFormAccess
									type="mentee"
									programData={currentProgram}
									applicationType={formsAvailability.menteeRecruitment ? 'proper' : 'entry'}
									recruitmentEnabled={formsAvailability.isMenteeRecruitmentAvailable}
								/>
							}
						/>
					</Route>
				</Route>
				<Route path="*" element={<PageMissing />} />
			</Routes>
		</>
	);
};

export default ProgramLandingPage;
