import React, { useState, ReactNode, PropsWithChildren } from 'react';
import ChangeOrganization from 'components/_common/panel/ChangeOrganization';
import { border, colors } from 'theme';
import { MenuItemExt } from 'components/_common/PanelMenu';
import { Dialog } from 'components/_new/Dialog';
import Navigation from './Navigation';

type PanelLayoutProps = PropsWithChildren<{
	header?: ReactNode | (() => ReactNode);
	right?: ReactNode | (() => ReactNode);
	navigationOptions: MenuItemExt[];
	navigationTop?: ReactNode | (() => ReactNode);
	navigationBottom?: ReactNode | (() => ReactNode);
}>;

const PanelLayout = (props: PanelLayoutProps) => {
	const { header, navigationOptions, navigationTop, navigationBottom, right, children } = props;

	const [showOrgDialog, setShowOrgDialog] = useState(false);

	const renderHeader = (): ReactNode => {
		if (header) {
			if (typeof header === 'function') {
				return header();
			}
			return header;
		}
		return null;
	};

	// scroll to top on navigation change
	// const { pathname } = useLocation();
	// useEffect(() => {
	// 	const el = document.getElementById('scrollWrapperRef');
	// 	if (el) {
	// 		el.scrollTo(0, 0);
	// 	}
	// }, [pathname]);

	const renderRight = (): ReactNode => {
		if (right) {
			let content = null;
			if (typeof right === 'function') {
				content = right();
			} else {
				content = right;
			}
			return (
				<div
					id="scrollWrapperRightRef"
					className="hidden lg:flex p-styled-scrollbar p-styled-scrollbar-purplishblue overflow-y-auto"
					style={{ minWidth: 240, maxWidth: 240 }}
				>
					{content}
				</div>
			);
		}
		return null;
	};

	return (
		<div className="flex flex-1 max-h-screen">
			<Navigation navigationOptions={navigationOptions} top={navigationTop} bottom={navigationBottom} />

			<div className="flex flex-1 flex-column pr-0 sm:pr-3 overflow-x-hidden">
				<div className="flex align-items-center h-5rem py-2" style={{ borderBottom: border(colors.lightGray) }}>
					{renderHeader()}
				</div>

				<div className="flex-1 flex justify-content-between py-3 overflow-y-auto">
					<div
						id="scrollWrapperRef"
						className="p-styled-scrollbar p-styled-scrollbar-purplishblue flex-1 px-2 pr-3 overflow-y-auto"
					>
						<div id="scrollWrapperInnerRef" className="hidden-accessible" />
						{children}
					</div>
					{renderRight()}
				</div>

				<Dialog
					title="Zmiana organizacji"
					size="sm"
					onHide={() => setShowOrgDialog(false)}
					visible={showOrgDialog}
				>
					<ChangeOrganization card onCancel={() => setShowOrgDialog(false)} />
				</Dialog>
			</div>
		</div>
	);
};

export default PanelLayout;
