import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePanel } from 'contexts/panelContext';
import { Pair } from 'types/Pair';
import { colors } from 'theme';
import { Button } from 'primereact/button';
import PairPersonalInfo from 'assets/icons/pair-personal-info.svg';
import BusinessCard from 'components/_common/panel/BusinessCard';
import { GroupedChoiceList } from 'types/Question';
import { userDisplayName } from 'utils/userDisplayName';
import { Box, BoxSection } from 'components/_new/Box';
import { Avatar } from 'components/_new/Avatar';
import { Dialog } from 'components/_new/Dialog';
import OptionItem from 'components/pairing/OptionItem';
import { Application } from 'types/Application';
import { prepareUserAreas } from 'utils/prepareUserAreas';
import { parseHtml } from 'utils/htmlParser';

interface PairProps {
	key: string | number;
	data: Application;
	userAreas?: string[];
	rednerBottom?: () => React.ReactNode;
}
interface Option {
	area: string;
	match: boolean;
}

const PairItem = ({ key, data, userAreas, rednerBottom }: PairProps) => {
	const { t } = useTranslation();
	const { panelType } = usePanel();

	const {
		programMembership: { user },
		systemAnswers: { position, expectations, areas, department },
	} = data;

	const [togglePersonalDetails, setTogglePersonalDetails] = useState(false);

	const getOptions = () => {
		if (areas) {
			const pairAreas = prepareUserAreas(areas);
			const separatedAreas: Option[] = [];
			pairAreas?.forEach((area) => {
				const isAreaMatch = userAreas?.find((userArea) => userArea === area);
				if (isAreaMatch) return separatedAreas.unshift({ area, match: true });
				return separatedAreas.push({ area, match: false });
			});
			return separatedAreas;
		}
		return [];
	};

	const displayName = userDisplayName(user);

	const renderContent = (content: any, separator = ', ') => {
		if (Array.isArray(content)) {
			return content.join(separator);
		}
		return parseHtml(content);
	};

	const renderTitle = () => (
		<div className="flex flex-column w-full justify-content-between sm:flex-row">
			<Button
				onClick={() => setTogglePersonalDetails(true)}
				className="flex flex-row align-items-center p-button p-button-link p-0 color-inherit text-left"
			>
				<div className="flex flex-row justify-content-start gap-3 w-full">
					<Avatar src={user.avatar} size="lg" name={userDisplayName(user, null)} />
					<div>
						<div className="font-semibold text-3xl mb-1">{displayName}</div>
						<div className="font-semibold">
							{`${position ? `${renderContent(position)}` : ''}${
								department ? `, ${renderContent(department)}` : ''
							}`}
						</div>
					</div>
				</div>
			</Button>
			<div
				className="font-normal flex align-items-center text-xs  md:ml-0 ml-7"
				style={{ color: colors.purplishBlue }}
			>
				<Button className="p-button-text" onClick={() => setTogglePersonalDetails(true)}>
					<img src={PairPersonalInfo} className="pr-2" alt="person" />
					{t('userPanel.pairing.personalDetails')}
				</Button>
			</div>
		</div>
	);
	return (
		<div key={key} className="mb-4">
			<Box variant="white-bordered">
				<BoxSection header={renderTitle()}>
					<div>
						<div className="color-gray">
							{panelType === 'mentee'
								? t('userPanel.pairing.mentorCompentences')
								: t('userPanel.pairing.menteeDevelopmentAres')}
							:
						</div>
						<div className="flex mt-1 gap-2 flex-wrap flex-start flex-column sm:flex-row">
							{getOptions().map((area) => (
								<OptionItem key={area.area} option={area} />
							))}
						</div>
					</div>
					<div className="py-4">
						<div className="color-gray">
							{panelType === 'mentee'
								? t('userPanel.pairing.mentorCanOffer')
								: t('userPanel.pairing.menteeGoalsInProgram')}
							:
						</div>
						<div className="font-italic">{renderContent(expectations)}</div>
					</div>
					{rednerBottom && <div>{rednerBottom()}</div>}
				</BoxSection>
			</Box>

			<Dialog
				title={t('userPanel.pairing.personalDetailsPair', {
					type: panelType === 'mentee' ? t('misc.mentors') : t('misc.mentees'),
				})}
				visible={togglePersonalDetails}
				onHide={() => setTogglePersonalDetails(false)}
				size="lg"
				className="p-dialog-buissnescard"
			>
				<BusinessCard data={data} disableMessage />
			</Dialog>
		</div>
	);
};

export default PairItem;
