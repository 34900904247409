/* eslint-disable import/no-cycle */
import { LandingContent } from 'types/LandingContent';
import { Organization } from './Organization';
import { ProgramMembership } from './Membership';

export enum ProgramLandingPageMode {
	HTML = 'html',
	MODULE = 'module',
}

export interface ProgramAlgorithm {
	maxMentorDistance: number;
	minCommonSkills: number;
	skillMultiplier: number;
	goalMultiplier: number;
	sameDepartmentPenalty: number;
	matchingBonus: number;
	pairsToCreate: number;
}

export interface ProgramLandingPage {
	id: number;
	// landingContent: LandingContent | null;
	htmlContent: string | null;
}

export interface ProgramPairStats {
	programPairCount: number;
	programPairAcceptedCount: number;
	programPairWithSessionInLast30DaysCount: number;
	programPairWithoutSessionInLast30DaysCount: number;
	programPairWithoutSignedContractCount: number;
}

export interface ProgramApplicationStats {
	applicationCount: number;
	mentorApplicationCount: number;
	mentorApplicationPendingCount: number;
	menteeApplicationCount: number;
	menteeApplicationPendingCount: number;
	activeApplicationsInLast30DaysCount: number;
	unactiveApplicationsInLast30DaysCount: number;
	menteesWithoutPairCount: number;
	mentorsWithoutPairCount: number;
}

export interface ProgramQuestionStats {
	applicationQuestionMenteeCount: number;
	applicationQuestionMentorCount: number;
}

export interface ProgramStats {
	// applicationQuestionMenteeCount: number;
	// applicationQuestionMentorCount: number;
	// applicationCount: number;
	// mentorApplicationCount: number;
	// mentorApplicationPendingCount: number;
	// menteeApplicationCount: number;
	// menteeApplicationPendingCount: number;
	// activeApplicationsInLast30DaysCount: number;
	// unactiveApplicationsInLast30DaysCount: number;
	// programPairCount: number;
	// programPairAcceptedCount: number;
	// programPairWithSessionInLast30DaysCount: number;
	// programPairWithoutSessionInLast30DaysCount: number;
	// programPairWithoutSignedContractCount: number;
	// menteesWithoutPairCount: number;
	// mentorsWithoutPairCount: number;
	contractFieldCount: number;
	menteeAvgSatisfaction: number | null;
	mentorAvgSatisfaction: number | null;
	usersWithoutApplicationCount: number;
}

export interface Program {
	id: number;
	displayName: string;
	authRequired: boolean;
	entryMenteeRecruitment: boolean;
	entryMentorRecruitment: boolean;
	finished: boolean;
	// landingContent: LandingContent;
	mailRegex: string;
	language: string;
	menteeRecruitment: boolean;
	mentorRecruitment: boolean;
	name: string;
	pairingStatus: boolean;
	organizationId: number;
	programType: string;
	requiresApplicationApproval: boolean;
	shortName: string;
	memberships?: ProgramMembership[];
	organization?: Organization;
	mentibotEnabled: boolean;
	mentorLabelFileId: number | null;
	menteeLabelFileId: number | null;
	browseMembersByMentor: boolean;
	browseMembersByMentee: boolean;
	requireTwoFactorAuth: boolean;
	endDate: Date | null;
	numberOfSessions: number;

	subtitle: string | null;
	logotype: string | null;
	landingPageMode: string;
	nextProgramName: string | null;

	// additional
	isProgramAdmin?: boolean;
	isProgramOrganizationAdmin?: boolean;

	contact?: string;
	algorithm?: ProgramAlgorithm;

	stats?: ProgramStats;
	pairStats: ProgramPairStats;
	applicationStats: ProgramApplicationStats;
	questionStats: ProgramQuestionStats;
}

export interface ProgramWithLanding extends Program {
	landingPage: ProgramLandingPage;
	landingPageModules: ProgramLandingPageModule[];
}

export interface ProgramInfo extends Program {
	membership: ProgramMembership;
}

export interface UpdateProgramStatusParametersPayload {
	displayName: string;
	subtitle: string;
	logotype?: File;
	finished: boolean;
	endDate: string | null;
	landingPageMode: string;
}

export interface UpdateProgramRecruitmentParametersPayload {
	menteeRecruitment: boolean;
	mentorRecruitment: boolean;
	requiresApplicationApproval: boolean;
}

export interface UpdateProgramPairsParametersPayload {
	pairingStatus: boolean;
	browseMembersByMentee: boolean;
	browseMembersByMentor: boolean;
	pairsToCreate: number;
	maxMentorDistance: number;
	minCommonSkills: number;
	skillMultiplier: number;
	sameDepartmentPenalty: number;
	matchingBonus: number;
}

export interface UpdateProgramSettingsParametersPayload {
	numberOfSessions: number;
	mentibotEnabled: boolean;
	requireTwoFactorAuth: boolean;
	menteeLabelFileId: number | null;
	mentorLabelFileId: number | null;
}

export enum ProgramLandingPageModuleType {
	HEADER = 'header',
	WYSWIG = 'wyswig',
	HIGHLIGHT = 'highlight',
	TEXT = 'text',
	DOUBLE = 'double',
}

export type ProgramLandingPageModuleDefineFieldType = 'image' | 'text' | 'wyswig' | Array<string>;
export type ProgramLandingPageModuleDefine = {
	type: ProgramLandingPageModuleType;
	template: string | Record<string, string> | null;
	fields: Record<string, ProgramLandingPageModuleDefineFieldType>;
};

export interface ProgramLandingPageModule {
	id: number;
	programId: number;
	module: ProgramLandingPageModuleType;
	sequence: number;
	content: Record<string, string | number> | null;
	compiled: string;
	define: ProgramLandingPageModuleDefine | null;
}

export type ProgramLandingPageModulesAddPayload = ProgramLandingPageModulesEditPayload & {
	type: string;
};

export type ProgramLandingPageModulesEditPayload = {
	sequence: number;
	fields: Array<{ fieldName: string; value: string | number | boolean }>;
};

export type ProgramLandingPageModulesSequencePayload = Array<{ id: number; sequence: number }>;

export type ProgramLandingPageModulesImagePayload = {
	file: File;
};
